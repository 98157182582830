<template>
    <!-- BEGIN: Content-->
    <div class="mt-3">
      <loader/>
      <div class="bookingEdit">
        <v-container fluid style="padding-top:0 !important;padding-bottom:0 !important">
            <v-row style="margin-top:0 !important;margin-bottom:0 !important">
              <v-col md="7" xl="5" lg="7" sm="12" cols="12" class="my-1 py-0">
              <v-card class="card-shadow" v-if="isShow">
                <div style="width:100%;height:.3rem !important">
                    <v-progress-linear
                      color="#F9780D"
                      indeterminate
                      height="3"
                      :active="addbookingloader"
                    ></v-progress-linear>
                  </div>
                <v-form class="pt-0 px-3"  enctype="multipart/form-data" v-model="valid" ref="form">
                  <v-row class="py-4" style="padding-bottom:0 !important">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"

                      >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            type="tel" 
                            v-mask="'####-##-##'"
                            label="Pickup date(YYYY-MM-DD)*"
                            :rules="fieldRules"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hide-details
                            @click:clear="clearpickupdate"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          :min="new Date().toISOString().substr(0, 10)"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="#f9780d"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        v-model="time"
                        label="Pickup Time*"
                        type="tel" 
                        v-mask="'##:##'"
                        :rules="fieldRules"
                        required
                        list="data"
                        hide-details
                        autocomplete="off"
                      ></v-text-field>
                      <datalist id="data">
                          <option v-for="time in $store.state.times" :value="time" />
                      </datalist>
                    </v-col>

                    <v-col cols="12">
                      <v-switch
                        v-model="isDelivery"
                        hide-details
                        label="Delivery"
                      ></v-switch>
                    </v-col>

                    <v-row
                      v-for="(passenger, indx) in passengers" 
                        :key="indx"
                        style="display:flex;align-items:center;justify-content:space-between"
                        class="mx-2 my-2"
                      >
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        >
                      
                            <v-autocomplete
                              v-if="passengers[indx]['isTextField'] === false"
                              :items="customers"
                              :label="Plabel"
                              v-model="passenger.id" 
                              :name="`passengers[${indx}][id]`"
                              item-text="name"
                              item-value="id"
                              :rules="fieldRules"
                              @change="(event) => updateCustomer(event,indx)"
                              hide-details
                            >
                              
                              <div
                                  slot="prepend-item"
                                  class="grey--text px-4 pt-4 newcustomer"
                                  @click="setText(indx)"
                                  >
                                <p>New Customer</p>
                              </div>
                              <template slot="selection" slot-scope="{ item, selected }">
                                  {{ item.name }}
                              </template>
                              <template slot="item" slot-scope="{ item, tile }">
                                  {{ item.name }}
                              </template>

                            </v-autocomplete>
                            <v-text-field
                              v-if="passengers[indx]['isTextField'] === true"
                              append-icon="mdi-selection-ellipse-arrow-inside"
                              @click:append="setText(indx)"
                              :label="Plabel"
                              v-model="passenger.name" 
                              :name="`passengers[${indx}][name]`"
                              required
                              :rules="fieldRules"
                              hide-details
                            ></v-text-field>

                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                            <v-text-field
                              label="Telephone*"
                              required
                              v-model="passenger.telephone" 
                              :name="`passengers[${indx}][telephone]`"
                                :rules="fieldRules"
                                hide-details
                              ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            >
                            <v-text-field
                              label="Email"
                              v-model="passenger.email" 
                              :name="`passengers[${indx}][email]`"
                                hide-details
                              ></v-text-field>
                          </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="2"
                          >
                          <div class="form-group" style="display:flex;align-items:center;justify-content:center">
                            <v-icon @click="addPassenger" class="mx-3">mdi-plus</v-icon>
                            <v-icon @click="removePassenger(indx)">mdi-close</v-icon>
                          </div>
                      </v-col>
                    </v-row>

                    <v-col cols="12" v-if="!isDelivery && organisation.hascodes != undefined && organisation.hascodes == '0'" class="pt-0 mt-0 mb-3">
                        <v-switch
                          v-model="useSubProjects"
                          hide-details
                          label="Use Sub Projects"
                          @change="onSwitchchange"
                        ></v-switch>
                      </v-col>

                    <v-row class="mb-2 mx-2" v-if="!useSubProjects && !isDelivery && organisation.hascodes != undefined && organisation.hascodes == '0'">

                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-autocomplete
                          :items="projects"
                          label="Select project"
                          v-model="item.project_id"
                          :value="item.project_id"
                          item-text="name"
                          item-value="id"
                          multiple
                          chips
                          deletable-chips
                          :search-input.sync="searchproject"
                          hide-details
                        ></v-autocomplete>
                      </v-col>

                    </v-row>

                    <v-row class="mb-2 mx-2" v-if="useSubProjects && !isDelivery && organisation.hascodes != undefined && organisation.hascodes == '0'">
                      
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          v-for="(subproject,index) in projects"
                          :key="index"
                        >
                          <v-autocomplete
                            :items="subproject.subprojects"
                            :label="`Select ${capitalize(subproject.name.toLowerCase())}`"
                            :value="item.subproject_id"
                            item-text="name"
                            item-value="id"
                            chips
                            required
                            hide-details
                            :rules="fieldRules"
                            deletable-chips
                            @change="(event) => updateSubprojects(event, index, subproject.id)"
                          ></v-autocomplete>
                        </v-col>

                    </v-row>
                    
                    <v-col sm="12" md="12" cols="12" v-if="organisation.hascodes != undefined && organisation.hascodes == '1'">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6" 
                          md="3"
                          v-for="(value, key) in codes" :key="key"
                          >
                          <v-text-field
                            :label="formatLabel(key)"
                            v-model="codes[key]" :id="key"
                            hide-details
                            :rules="organisation.mandatory == '1' || parseInt(organisation.mandatory) == 1 ? fieldRules : []"
                            :required="organisation.mandatory == '1' || parseInt(organisation.mandatory) == 1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col sm="12" md="12" cols="12" class="d-flex align-center mb-1">
                      <label class="mt-1 mr-2">Location Type</label>
                      <v-radio-group
                        v-model="item.location_type"
                        row
                        mandatory
                        hide-details
                        class="mt-0 pt-0"
                      >
                        <v-radio
                          label="Map"
                          value="map"
                        ></v-radio>
                        <v-radio
                          label="Manual"
                          value="manual"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="12"
                        sm="12"
                        md="12">

                        <v-row>

                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Pick-up*"
                              required
                              :rules="fieldRules"
                              v-model="item.from" 
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Pick-up*</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedFrom"
                              @noresult="onNoResult"
                              >

                                <v-text-field
                                  label=""
                                  required
                                  hide-details
                                  :rules="fieldRules"
                                  v-model="item.from" 
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Drop-off*"
                              required
                              :rules="fieldRules"
                              v-model="item.to" 
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Drop-off*</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedTo"
                              @noresult="onNoResult"
                              >
                              <v-text-field
                                  label=""
                                  required
                                  hide-details
                                  :rules="fieldRules"
                                  v-model="item.to" 
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            style="justify-content: center !important; display: flex !important;align-items:center !important"
                            >
                            <v-icon class="mx-2" @click="shufflestops" style="width: 30px;height: 30px;">mdi-plus</v-icon>
                          </v-col>

                        </v-row>
                      
                    </v-col>

                    <v-col cols="12"
                        sm="12"
                        md="12"
                        v-show="hidestops"
                        >

                        <v-row>

                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Add Stop"
                              v-model="item.stop1" 
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Add Stop</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedStop1"
                              @noresult="onNoResult"
                              >

                                <v-text-field
                                  label=""
                                  hide-details
                                  v-model="item.stop1" 
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5" 
                            md="5"
                            v-if="item.location_type == 'manual'"
                            >
                            <v-text-field
                              label="Add Stop"
                              v-model="item.stop2" 
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                            md="5"
                            v-if="item.location_type == 'map'"
                            >
                            <label>Add Stop</label>
                            <VueGooglePlaces
                              :api-key="apiKey"
                              country="ke"
                              @placechanged="onPlaceChangedStop2"
                              @noresult="onNoResult"
                              >
                              <v-text-field
                                  label=""
                                  hide-details
                                  v-model="item.stop2" 
                                ></v-text-field>

                            </VueGooglePlaces>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                            style="justify-content: center !important; display: flex !important;align-items:center !important"
                            >
                            <v-icon class="mx-2" @click="shufflestops" style="width: 30px;height: 30px;">mdi-close</v-icon>
                          </v-col>

                        </v-row>
                      
                    </v-col>

                    <v-col sm="6" md="6" cols="12" v-if="!isDelivery">
                      <label>Trip Type</label>
                      <v-radio-group
                        v-model="item.trip_type"
                        row
                        mandatory
                        hide-details
                      >
                        <v-radio
                          label="One Way"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Wait and Return"
                          value="2"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col sm="6" md="6" cols="12" v-if="!isDelivery">
                      <label>Request Type</label>
                      <v-radio-group
                        v-model="item.request_type"
                        row
                        hide-details
                      >
                        <v-radio
                          label="Official"
                          value="Official"
                        ></v-radio>
                        <v-radio
                          label="Private"
                          value="Private"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-autocomplete
                        :items="vehicletype"
                        label="Select vehicle type"
                        v-model="item.vehicle_type_id"
                        item-text="type"
                        item-value="id"
                        :rules="fieldRules"
                        hide-details
                        :search-input.sync="searchvehicle"
                      ></v-autocomplete>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        label="Ordered by"
                        v-model="item.orderedby"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      >
                      <v-text-field
                        label="Flight details"
                        v-model="item.flight"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6" md="6"
                      >
                      <v-text-field
                        label="Remarks"
                        v-model="item.remarks"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        depressed
                        color="primary darken-1"
                        :disabled="!valid"
                        @click="proceed"
                        v-if="item.location_type == 'map'"
                      >
                        Proceed
                      </v-btn>
                      <v-btn
                        depressed
                        color="primary darken-1"
                        :disabled="!valid"
                        :loading="loading"
                        @click="create"
                        v-if="item.location_type == 'manual'"
                      >
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
            <v-col md="4" lg="6" sm="12" cols="12">
              <v-card class="card-shadow pa-0">
                
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!--confirm details modal -->
        <v-dialog
          v-model="confirmdialog"
          max-width="420"
          >
            <v-card
              width="420"
              class="mx-auto"
            >
              <v-card-title class="white--text pt-2">
                <div style="color:#000;font-size:1rem !important;font-weight:500 !important">
                  Booking Details
                </div>
              </v-card-title>

              <v-card-text class="mt-2">
                <v-simple-table class="requestsummary" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="white-space:nowrap;">Pickup Date:</td>
                        <td>{{ date }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Pickup Time:</td>
                        <td>{{ time }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">From:</td>
                        <td>{{ item.from }}</td>
                      </tr>
                      <tr v-if="item.stop1 !== ''">
                        <td style="white-space:nowrap;">Stop:</td>
                        <td>{{ item.stop1 }}</td>
                      </tr>
                      <tr v-if="item.stop2 !== ''">
                        <td style="white-space:nowrap;">Stop:</td>
                        <td>{{ item.stop2 }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">To:</td>
                        <td>{{ item.to }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Vehicle:</td>
                        <td>{{ rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].type.type : "N/A"  }}</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Distance:</td>
                        <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end)))) | currency('',2) }}Km</td>
                      </tr>
                      <tr>
                        <td style="white-space:nowrap;">Time:</td>
                        <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1Time)+parseFloat(stop2Time)+parseFloat(endTime)))) | currency('',2) }}Min</td>
                      </tr>
                      <tr v-if="rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0">
                        <td style="white-space:nowrap;padding-top:1rem !important">Total Amt:</td>
                        <td style="font-size:1rem !important;font-weight:500 !important;padding-top:1rem !important" v-if="parseInt(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate) === 0">{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end))*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? parseFloat(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].rate) : 0)))  | currency('') }}</td>
                        <td style="font-size:1rem !important;font-weight:500 !important;padding-top:1rem !important" velse-if="parseInt(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate) > 0">
                          <span style="font-weight:bold !important" v-if="parseInt((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end))*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? parseFloat(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].rate) : 0)) < parseInt(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate)">{{ (parseFloat(item.trip_type)*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].base_rate)) | currency('') }}</span>
                          <span style="font-weight:bold !important" v-else>{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end))*(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id)).length > 0 ? parseFloat(rates.filter(itm => itm.vehicle_type_id === parseInt(item.vehicle_type_id))[0].rate) : 0)))  | currency('') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    depressed
                    color="dark darken-1"
                    @click="confirmdialog = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary darken-1"
                    :loading="loading"
                    :disabled="!valid"
                    @click="create"
                  >
                    Update
                  </v-btn>
              </v-card-actions>
            </v-card>

        </v-dialog>
        <!-- end confirm details -->
      </div>
    </div>

</template>
<script>
import axios from 'axios'
import { VueGooglePlaces } from 'vue-google-places'
import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'
import {ability} from '@/services/ability'
import loader from '@/components/loader'

export default {
    name:"bookingEdit",
    components: { VueGooglePlaces,loader },
    data(){
        return {
          isShow: true,
          isDelivery:false,
          useSubProjects:false,
          bulkchecked:false,
          country: 'ke',
          apiKey: 'AIzaSyBOq3xAvXV2SsaJPxNk3iz4XNIKPdz4gRc',
          // apiKey: 'AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8',
          searchResults: [],
          service: null,
          map: null,
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          search:null,
          valid: true,
          menu3:false,
          searchvehicle:null,
          searchproject:null,
          searchfrequency:null,
          dialog: false,
          updatedialog: false,
          sub_projects: [],
          sprojects: [],
          menu: false,
          time: "",
          menu2: false,
          date: "", 
          enddate: "",
          item:{
              distance: "",
              to: "",
              from: "",
              fromlat: "",
              fromlong: "",
              tolat: "",
              tolong: "",

              stop1: "",
              stop1lat: null,
              stop1long: null,
              stop2: "",
              stop2lat: null,
              stop2long: null,

              trip_type: "",
              request_type: "",
              location_type: "map",
              project_id: [],
              name: "",
              telephone:"",
              orderedby:"",
              pickupdate:"",
              pickuptime:"",
              organisation_id:null,
              customer_id:"",
              vehicle_id:"",
              vehicle_type_id:"",
              flight:"",
              frequency:"",
              enddate:"",
              remarks:"",
              _method:"patch"
            },
            passengers: [],
            codes: {},
            loading: false,
           disabled: false,
           orgCategory: [],
           isBusy: false,
            updateModal: null,
            hidestops:false,
            confirmdialog: false,
        }
    },
    mounted(){

      this.$store.state.loader_text = 'Edit Booking'

      if(this.customers.length == 0 || this.projects.length == 0 || this.vehicletype.length == 0){
        this.getAddBookings()
      }
      this.showBookings()

      this.$store.state.stop1 = 0
      this.$store.state.stop2 = 0
      this.$store.state.end = 0

      this.codes = {}
      if(this.organisation.hascodes == "1") {
        this.organisation.codes.split(",").forEach(key => {
          this.$set(this.codes, key.trim(), "");
        });
        this.item.project_id = []
        this.sub_projects = []
        this.useSubProjects = false
      }

    },
    created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }

    },
    computed: {
      Plabel() {
        return !this.isDelivery ? "Passenger Name*" : "Contact Name*"
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
      vehicletype() {
        return this.$store.state.bookingvehicletype
      },
      customers() {
        return this.$store.state.bookingcustomers
      },
      rates() {
        return this.$store.state.rates       
      },
      projects() {
          return this.$store.state.bookingprojects      
      },
      organisation_id() {
        return this.$store.state.user.user.organisation.id
      },
      organisation() {
        return this.$store.state.user.user.organisation
      },
      bookings() {
        return this.$store.state.bookings
      },
      distance() {
        return this.$store.state.distance
      },
      stop1() {
        return this.$store.state.stop1
      },
      stop2() {
        return this.$store.state.stop2
      },
      end() {
        return this.$store.state.end
      },
      distanceTime() {
        return this.$store.state.distanceTime
      },
      stop1Time() {
        return this.$store.state.stop1Time
      },
      stop2Time() {
        return this.$store.state.stop2Time
      },
      endTime() {
        return this.$store.state.endTime
      }
    },
    watch: {
        distance: function(newVal, oldVal) {
            this.$store.state.distance = newVal
        },
        stop1: function(newVal, oldVal) {
            this.$store.state.stop1 = newVal
        },
        stop2: function(newVal, oldVal) {
            this.$store.state.stop2 = newVal
        },
        end: function(newVal, oldVal) {
            this.$store.state.end = newVal
        }
    },
    methods:{

      formatLabel(key) {
        return key !== "" 
          ? key.trim().split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ") 
          : "";
      },

      clearpickupdate() {
        this.date = ""
      },
      addPassenger () {
        this.passengers.push({
          organisation_id: this.organisation_id,
          id: '',
          name: '',
          telephone: '',
          email: '',
          isTextField: false
        })
      },
      removePassenger (index) {
        this.passengers.splice(index, 1);
      },
      onSwitchchange($event) {
        if($event === true || $event === 'true' || $event === 'on') {
          this.item.project_id = []
        }else {
          this.sub_projects = []
        }
      },
      capitalize(name) {
        const finalSentence = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence
      },
      updateSubprojects(value, index, projectId) {

        if(value !== null) {

          const val = {
                        project_id: projectId,
                        subproject_id: value
                      }

          let index = this.sub_projects.findIndex(data => data.project_id == projectId && data.subproject_id == value);
          if (index > -1) {
            
            this.sub_projects.splice(index, 1, val);
          }else {
            this.sub_projects.unshift(val)
          }

        }else {

          let index = this.sub_projects.findIndex(data => data.project_id == projectId);
          if (index > -1) {
            this.sub_projects.splice(index, 1);
          }

        }
        
      },
      shufflestops() {
        this.hidestops = !this.hidestops
        this.item.stop1 = ""
        this.item.stop1lat = null
        this.item.stop1long = null
        this.item.stop2 = ""
        this.item.stop2lat = null
        this.item.stop2long = null

        this.$store.state.waypoints = []
      },

      setText(indx) {
        
        let find = this.passengers[indx];
        if (find) {
          const container = {};
          container.organisation_id = this.organisation_id
          container.id = ''
          container.name = ''
          container.telephone = ''
          container.email = ''
          container.isTextField = !find.isTextField
          this.passengers.splice(indx, 1, container);
        }
        let found = this.passengers[indx];

        if(found.isTextField === true) {
          this.item.telephone = ''
          this.item.customer_id = null
          this.selectcustomer = null

          if (found) {
            const container = {};
            container.organisation_id = this.organisation_id
            container.id = ''
            container.telephone = ''
            container.email = ''
            container.name = found.name
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }

        }
        if(found.isTextField === false) {
          this.item.name = ''
          if (found) {
            const container = {};
            container.organisation_id = this.organisation_id
            container.id = ''
            container.telephone = ''
            container.email = ''
            container.name = ''
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }
        }
      },
      updateCustomer(value,indx) {
        if(value !== null) {
          let index = this.customers.filter(item => item.id === value )

          let found = this.passengers[indx];
          if (found) {
            found.id = value
            found.name = index[0].name
            found.telephone = index[0].telephone
            found.email = index[0].email
            found.isTextField = false
          }
        }
      },

      directionFilter (addressData) {
        this.direction = addressData.name+', '+addressData.locality+', '+addressData.country
      },
      onPlaceChangedFrom (addressData) {
        this.item.fromlat = addressData.latitude
        this.item.fromlong = addressData.longitude
        this.item.from = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.origin = { lat: addressData.latitude, lng: addressData.longitude }
      },
      onPlaceChangedTo (addressData) {
        this.item.tolat = addressData.latitude
        this.item.tolong = addressData.longitude
        this.item.to = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.destination = { lat: addressData.latitude, lng: addressData.longitude }
      },
      onPlaceChangedStop1 (addressData) {
        this.item.stop1lat = addressData.latitude
        this.item.stop1long = addressData.longitude
        this.item.stop1 = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });
      },
      onPlaceChangedStop2 (addressData) {
        this.item.stop2lat = addressData.latitude
        this.item.stop2long = addressData.longitude
        this.item.stop2 = addressData.name+', '+addressData.locality+', '+addressData.country

        this.$store.state.waypoints.push({
                        location: {lat: addressData.latitude, lng: addressData.longitude},
                        stopover: true
                      });
      },
      onNoResult () {},

      showBookings(){
          axios.get(`/bookings/${this.$route.params.id}`).then(response=>{

            let pprojects = response.data.booking.bookingprojects.map(item => {
                              return item.id
                          });

            this.item.customer_id = response.data.booking.passenger_id
            this.item.name = response.data.booking.passenger_name
            this.item.telephone = response.data.booking.telephone
            this.date = response.data.booking.pickup_date
            if(response.data.booking.pickup_time !== null  && response.data.booking.pickup_time !== "null"  && response.data.booking.pickup_time !== "") {
              this.time = response.data.booking.pickup_time
            }
            this.item.organisation_id = parseInt(response.data.booking.organisation_id)
            this.item.vehicle_id = parseInt(response.data.booking.vehicle_id)
            this.item.vehicle_type_id = parseInt(response.data.booking.vehicle_type_id)
            this.item.request_type = response.data.booking.request_type
            this.item.location_type = response.data.booking.location_type
            this.item.trip_type = response.data.booking.trip_type
            this.item.project_id = pprojects
            this.item.distance = response.data.booking.distance
            this.item.from = response.data.booking.travelfrom
            this.item.to = response.data.booking.travelto
            this.item.tolat = response.data.booking.tolat
            this.item.tolong = response.data.booking.tolong
            this.item.fromlong = response.data.booking.fromlong
            this.item.fromlat = response.data.booking.fromlat

            if(response.data.booking.request_category === "Delivery") {
              this.isDelivery = true
            }

            if(response.data.booking.bookingpassengers.length > 0) {

              response.data.booking.bookingpassengers.forEach((value, index) => {
                  this.passengers.push({
                    organisation_id: response.data.booking.organisation_id,
                    id: value.id,
                    name: value.name,
                    telephone: value.telephone,
                    email: value.email !== null && value.email !== 'null' ? value.email : "",
                    isTextField: false
                  })
              });

            }else {

              this.passengers.push({
                organisation_id: response.data.booking.organisation_id,
                id: response.data.booking.passenger_id,
                name: response.data.booking.passenger_name,
                telephone: response.data.booking.telephone,
                email: response.data.booking.customer !== null && response.data.booking.customer !== 'null' ? response.data.booking.customer.email : "",
                isTextField: false
              })

            }

            if(response.data.booking.bookingsubprojects.length > 0) {

              this.useSubProjects = true

              response.data.booking.bookingsubprojects.forEach((value, i) => {

                this.updateSubprojects(value.id, i, value.project_id)

              });

            }

            if(response.data.booking.codes !== "" && response.data.booking.codes !== "null" && response.data.booking.codes !== null) {

              this.codes = response.data.booking.codes

            }else {
              this.organisation.codes.split(",").forEach(key => {
                this.$set(this.codes, key.trim(), "");
              });
            }


            if(response.data.booking.travelstop1 !== "" && response.data.booking.travelstop1 !== "null" && response.data.booking.travelstop1 !== null || response.data.booking.travelstop2 !== "" && response.data.booking.travelstop2 !== "null" && response.data.booking.travelstop2 !== null) {
              this.hidestops = true
              if(response.data.booking.travelstop1 !== "" && response.data.booking.travelstop1 !== "null" && response.data.booking.travelstop1 !== null) {
                this.item.stop1 = response.data.booking.travelstop1
                this.item.stop1lat = response.data.booking.stop1lat
                this.item.stop1long = response.data.booking.stop1long
              }
              if(response.data.booking.travelstop2 !== "" && response.data.booking.travelstop2 !== "null" && response.data.booking.travelstop2 !== null) {
                this.item.stop2 = response.data.booking.travelstop2
                this.item.stop2lat = response.data.booking.stop2lat
                this.item.stop2long = response.data.booking.stop2long
              }
              
            }

            if(response.data.booking.travelfrom !== "" && response.data.booking.travelfrom !== "null" && response.data.booking.travelfrom !== null) {
              this.$store.state.origin = { lat: parseFloat(response.data.booking.fromlat), lng: parseFloat(response.data.booking.fromlong) }
            }
            if(response.data.booking.travelto !== "" && response.data.booking.travelto !== "null" && response.data.booking.travelto !== null) {
              this.$store.state.destination = { lat: parseFloat(response.data.booking.tolat), lng: parseFloat(response.data.booking.tolong) }
            }
            if(response.data.booking.travelstop1 !== "" && response.data.booking.travelstop1 !== "null" && response.data.booking.travelstop1 !== null) {
              this.$store.state.waypoints.push({
                        location: {lat: parseFloat(response.data.booking.stop1lat), lng: parseFloat(response.data.booking.stop1long)},
                        stopover: true
                      });
            }
            if(response.data.booking.travelstop2 !== "" && response.data.booking.travelstop2 !== "null" && response.data.booking.travelstop2 !== null) {
              this.$store.state.waypoints.push({
                        location: {lat: parseFloat(response.data.booking.stop2lat), lng: parseFloat(response.data.booking.stop2long)},
                        stopover: true
                      });
            }

            this.item.orderedby = response.data.booking.orderedby
            this.item.flight = response.data.booking.flight
            this.item.remarks = response.data.booking.remarks
          
        }).catch(error=>{
            console.log(error)
        })
      },

      getAddBookings (){
        this.$store.state.addbookingloader = true
        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      proceed() {

        if(this.organisation_id === parseInt(161) && this.item.project_id.length == 0) {
              this.$toast.warning('Projects are required to proceed','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

        if(this.item.fromlat === null || this.item.fromlat === "" || this.item.tolat === null || this.item.tolat === "") {
              this.$toast.warning('Every location should be picked from google suggestions','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

        if(this.item.stop1 !== "" && this.item.stop2 !== "") {
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), 'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), 'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 !== "" && this.item.stop2 === "") {
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long),'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 !== "") {
          this.$store.state.stop1 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long),'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 === "") {
          this.$store.state.stop1 = 0
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');
        }

        this.confirmdialog = true

      },
      create(){

          if (this.$refs.form.validate()) {

            if(this.organisation_id === parseInt(161) && this.item.project_id.length == 0) {
              this.$toast.warning('Projects are required to proceed','Warning',{position: 'topRight',timeout: 9000,})
              return
            }

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('customer_id',this.item.customer_id)
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('to',this.item.to)
            fd.append('from',this.item.from)
            fd.append('enddate',this.enddate)
            fd.append('frequency',this.item.frequency)
            fd.append('distance',(parseFloat(this.stop1) + parseFloat(this.stop2) + parseFloat(this.end)))
            fd.append('fromlat',this.item.fromlat)
            fd.append('fromlong',this.item.fromlong)
            fd.append('tolat',this.item.tolat)
            fd.append('tolong',this.item.tolong)

            fd.append('stop1lat',this.item.stop1lat)
            fd.append('stop1long',this.item.stop1long)
            fd.append('stop2lat',this.item.stop2lat)
            fd.append('stop2long',this.item.stop2long)
            fd.append('stop1',this.item.stop1)
            fd.append('stop2',this.item.stop2)

            fd.append('codes',JSON.stringify(this.codes))

            fd.append('triptime',(parseFloat(this.item.trip_type)*((parseFloat(this.stop1Time)+parseFloat(this.stop2Time)+parseFloat(this.endTime)))))

            fd.append('orderedby',this.item.orderedby)
            fd.append('pickupdate',this.date)
            fd.append('pickuptime',this.time)
            fd.append('flight',this.item.flight)
            fd.append('remarks',this.item.remarks)
            fd.append('organisation_id',this.organisation_id)
            fd.append('vehicle_type_id',this.item.vehicle_type_id)
            fd.append('project_id',JSON.stringify(this.item.project_id))
            fd.append('subprojects',JSON.stringify(this.sub_projects))
            fd.append('passengers',JSON.stringify(this.passengers))
            fd.append('trip_type',this.item.trip_type)
            fd.append('request_type',this.item.request_type)
            fd.append('location_type',this.item.location_type)
            fd.append('useSubProjects',this.useSubProjects)
            fd.append('isDelivery',this.isDelivery)
            fd.append('_method',this.item._method)

            axios.post(`/bookings/${this.$route.params.id}`, fd, config).then(response=>{

                if(response.data.status == parseInt(200)) {
                  this.$toast.success('Booking updated successfully','Success',{position: 'topRight',timeout: 7000,})
                  this.clear()
                }
                this.loading = false
                this.disabled = false
                this.confirmdialog = false

                this.$store.state.waypoints = []
                this.$store.state.origin = null
                this.$store.state.destination = null
                
                this.$router.push({ path: '/booking/history' })
                // this.$router.push({ name: 'bookingList' })
                
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
              })

          }

      },
      clear () {
        this.$refs.form.reset()
      }
    }
}
</script>